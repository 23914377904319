var exports = {};
const greekVowels = "\u03B1\u03B5\u03B7\u03B9\u03BF\u03C5\u03C9",
      greekConsonants = "\u03B2\u03B3\u03B4\u03B6\u03B8\u03BA\u03BB\u03BC\u03BD\u03BE\u03C0\u03C1\u03C2\u03C3\u03C4\u03C6\u03C7\u03C8",
      greekToPhoneticLatinMap = [{
  find: "\u03B7\u03C5",
  replace: "if"
}, {
  find: "(\u03B1\u03C5)(?=[" + greekConsonants + "])",
  replace: "af"
}, {
  find: "(\u03B1\u03C5)(?=[" + greekVowels + "])",
  replace: "av"
}, {
  find: "(\u03B5\u03C5)(?=[" + greekConsonants + "])",
  replace: "ef"
}, {
  find: "(\u03B5\u03C5)(?=[" + greekVowels + "])",
  replace: "ev"
}, {
  find: "\u03BF\u03C5",
  replace: "ou"
}, {
  find: "(\u0391\u03C5)(?=[" + greekConsonants + "])",
  replace: "Af"
}, {
  find: "(\u0391\u03C5)(?=[" + greekVowels + "])",
  replace: "Av"
}, {
  find: "(\u0395\u03C5)(?=[" + greekConsonants + "])",
  replace: "Ef"
}, {
  find: "(\u0395\u03C5)(?=[" + greekVowels + "])",
  replace: "Ev"
}, {
  find: "\u0397\u03C5",
  replace: "If"
}, {
  find: "\u039F\u03C5",
  replace: "Ou"
}, {
  find: "\u03B7\u03CD",
  replace: "\xEDf"
}, {
  find: "(\u03B1\u03CD)(?=[" + greekConsonants + "])",
  replace: "\xE1f"
}, {
  find: "(\u03B1\u03CD)(?=[" + greekVowels + "])",
  replace: "\xE1v"
}, {
  find: "(\u03B5\u03CD)(?=[" + greekConsonants + "])",
  replace: "\xE9f"
}, {
  find: "(\u03B5\u03CD)(?=[" + greekVowels + "])",
  replace: "\xE9f"
}, {
  find: "\u03BF\u03CD",
  replace: "o\xFA"
}, {
  find: "(\u0391\u03CD)(?=[" + greekConsonants + "])",
  replace: "\xC1f"
}, {
  find: "(\u0391\u03CD)(?=[" + greekVowels + "])",
  replace: "\xC1v"
}, {
  find: "(\u0395\u03CD)(?=[" + greekConsonants + "])",
  replace: "\xC9f"
}, {
  find: "(\u0395\u03CD)(?=[" + greekVowels + "])",
  replace: "\xC9v"
}, {
  find: "\u0397\u03CD",
  replace: "\xCDf"
}, {
  find: "\u039F\u03CD",
  replace: "O\xFA"
}, {
  find: "\u03B1\u03B9",
  replace: "e"
}, {
  find: "\u03B5\u03B9",
  replace: "i"
}, {
  find: "\u03BF\u03B9",
  replace: "i"
}, {
  find: "\u03B1\u03AF",
  replace: "\xE9"
}, {
  find: "\u03B5\u03AF",
  replace: "\xED"
}, {
  find: "\u03BF\u03AF",
  replace: "\xED"
}, {
  find: "\u0391\u03B9|\u0391\u0399",
  replace: "E"
}, {
  find: "\u0395\u03B9|\u0395\u0399",
  replace: "I"
}, {
  find: "\u039F\u03B9|\u039F\u0399",
  replace: "I"
}, {
  find: "\u0391\u03AF",
  replace: "\xC9"
}, {
  find: "\u0395\u03AF",
  replace: "\xCD"
}, {
  find: "\u039F\u03AF",
  replace: "\xCD"
}, {
  find: "\u03B3\u03B5",
  replace: "ye"
}, {
  find: "\u03B3\u03B9",
  replace: "yi"
}, {
  find: "\u03B3\u03C5",
  replace: "yi"
}, {
  find: "\u0393\u03B5",
  replace: "Ye"
}, {
  find: "\u0393\u03B9",
  replace: "Yi"
}, {
  find: "\u0393\u03C5",
  replace: "Yi"
}, {
  find: "\u03BC\u03C0",
  replace: "b"
}, {
  find: "\u03BD\u03C4",
  replace: "d"
}, {
  find: "\u03B3\u03BA",
  replace: "g"
}, {
  find: "\u039C\u03C0|\u039C\u03A0",
  replace: "B"
}, {
  find: "\u039D\u03C4|\u039D\u03A4",
  replace: "D"
}, {
  find: "\u0393\u03BA|\u0393\u039A",
  replace: "G"
}, {
  find: "\u03B1",
  replace: "a"
}, {
  find: "\u03B2",
  replace: "v"
}, {
  find: "\u03B3",
  replace: "g"
}, {
  find: "\u03B4",
  replace: "d"
}, {
  find: "\u03B5",
  replace: "e"
}, {
  find: "\u03B6",
  replace: "z"
}, {
  find: "\u03B7",
  replace: "i"
}, {
  find: "\u03B8",
  replace: "th"
}, {
  find: "\u03B9",
  replace: "i"
}, {
  find: "\u03BA",
  replace: "k"
}, {
  find: "\u03BB",
  replace: "l"
}, {
  find: "\u03BC",
  replace: "m"
}, {
  find: "\u03BD",
  replace: "n"
}, {
  find: "\u03BE",
  replace: "x"
}, {
  find: "\u03BF",
  replace: "o"
}, {
  find: "\u03C0",
  replace: "p"
}, {
  find: "\u03C1",
  replace: "r"
}, {
  find: "\u03C2",
  replace: "s"
}, {
  find: "\u03C3",
  replace: "s"
}, {
  find: "\u03C4",
  replace: "t"
}, {
  find: "\u03C5",
  replace: "i"
}, {
  find: "\u03C6",
  replace: "ph"
}, {
  find: "\u03C7",
  replace: "kh"
}, {
  find: "\u03C8",
  replace: "ps"
}, {
  find: "\u03C9",
  replace: "o"
}, {
  find: "\u03AC",
  replace: "\xE1"
}, {
  find: "\u03AD",
  replace: "\xE9"
}, {
  find: "\u03AE",
  replace: "\xED"
}, {
  find: "\u03AF",
  replace: "\xED"
}, {
  find: "\u03CC",
  replace: "\xF3"
}, {
  find: "\u03CD",
  replace: "\xED"
}, {
  find: "\u03CE",
  replace: "\xF3"
}, {
  find: "\u03B0",
  replace: "\xEF"
}, {
  find: "\u0390",
  replace: "\xEF"
}, {
  find: "\u03CA",
  replace: "\xEF"
}, {
  find: "\u03CB",
  replace: "\xEF"
}, {
  find: "\u0391",
  replace: "A"
}, {
  find: "\u0392",
  replace: "V"
}, {
  find: "\u0393",
  replace: "G"
}, {
  find: "\u0394",
  replace: "D"
}, {
  find: "\u0395",
  replace: "E"
}, {
  find: "\u0396",
  replace: "Z"
}, {
  find: "\u0397",
  replace: "I"
}, {
  find: "\u0398",
  replace: "Th"
}, {
  find: "\u0399",
  replace: "I"
}, {
  find: "\u039A",
  replace: "K"
}, {
  find: "\u039B",
  replace: "L"
}, {
  find: "\u039C",
  replace: "M"
}, {
  find: "\u039D",
  replace: "N"
}, {
  find: "\u039E",
  replace: "X"
}, {
  find: "\u039F",
  replace: "O"
}, {
  find: "\u03A0",
  replace: "P"
}, {
  find: "\u03A1",
  replace: "R"
}, {
  find: "\u03A3",
  replace: "S"
}, {
  find: "\u03A4",
  replace: "T"
}, {
  find: "\u03A5",
  replace: "I"
}, {
  find: "\u03A6",
  replace: "Ph"
}, {
  find: "\u03A7",
  replace: "Kh"
}, {
  find: "\u03A8",
  replace: "Ps"
}, {
  find: "\u03A9",
  replace: "O"
}, {
  find: "\u0386",
  replace: "\xC1"
}, {
  find: "\u0388",
  replace: "\xC9"
}, {
  find: "\u0389",
  replace: "\xCD"
}, {
  find: "\u038A",
  replace: "\xCD"
}, {
  find: "\u038C",
  replace: "\xD3"
}, {
  find: "\u038E",
  replace: "\xCD"
}, {
  find: "\u038F",
  replace: "\xD3"
}, {
  find: "\u03B0",
  replace: "\xCF"
}, {
  find: "\u0390",
  replace: "\xCF"
}, {
  find: "\u03AA",
  replace: "\xCF"
}, {
  find: "\u03AB",
  replace: "\xCF"
}];
exports = greekToPhoneticLatinMap;
export default exports;