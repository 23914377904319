var exports = {};
const diacriticsMap = [{
  find: "\u03AC\u1F00\u1F01\u1F02\u1F03\u1F04\u1F05\u1F06\u1F07\u1F70\u1F71\u1F80\u1F81\u1F82\u1F83\u1F84\u1F85\u1F86\u1F87\u1FB0\u1FB1\u1FB2\u1FB3\u1FB4\u1FB6\u1FB7",
  replace: "\u03B1"
}, {
  find: "\u0386\u1F08\u1F09\u1F0A\u1F0B\u1F0C\u1F0D\u1F0E\u1F0F\u1F88\u1F89\u1F8A\u1F8B\u1F8C\u1F8D\u1F8E\u1F8F\u1FB8\u1FB9\u1FBA\u1FBB\u1FBC",
  replace: "\u0391"
}, {
  find: "\u03AD\u1F10\u1F11\u1F12\u1F13\u1F14\u1F15\u1F72\u1F73",
  replace: "\u03B5"
}, {
  find: "\u0388\u1F18\u1F19\u1F1A\u1F1B\u1F1C\u1F1D",
  replace: "\u0395"
}, {
  find: "\u03AE\u1F20\u1F21\u1F22\u1F23\u1F24\u1F25\u1F26\u1F27\u1FC6\u1F74\u1FC7",
  replace: "\u03B7"
}, {
  find: "\u0389\u1F28\u1F29\u1F2A\u1F2B\u1F2C\u1F2D\u1F2E\u1F2F",
  replace: "\u0397"
}, {
  find: "\u03AF\u1F30\u1F31\u1F32\u1F33\u1F34\u1F35\u1F76\u1FD6",
  replace: "\u03B9"
}, {
  find: "\u038A\u1F36\u1F37\u1F38\u1F39\u1F3A\u1F3B\u1F3C\u1F3D\u1F3E\u1F3F",
  replace: "\u0399"
}, {
  find: "\u03CC\u1F40\u1F41\u1F42\u1F43\u1F44\u1F45\u1F78",
  replace: "\u03BF"
}, {
  find: "\u038C\u1F48\u1F49\u1F4A\u1F4B\u1F4C\u1F4D",
  replace: "\u039F"
}, {
  find: "\u03CD\u1F50\u1F51\u1F52\u1F53\u1F54\u1F55\u1F56\u1F57",
  replace: "\u03C5"
}, {
  find: "\u038E\u1F59\u1F5B\u1F5D\u1F5F",
  replace: "\u03A5"
}, {
  find: "\u03CE\u1F60\u1F61\u1F62\u1F63\u1F64\u1F65\u1F66\u1F67\u1FF6",
  replace: "\u03C9"
}, {
  find: "\u038F\u1F68\u1F69\u1F6A\u1F6B\u1F6C\u1F6D\u1F6E\u1F6F",
  replace: "\u03A9"
}];
exports = diacriticsMap;
export default exports;