var exports = {};
const greeklishToGreekMap = [{
  find: "tha",
  replace: "\u03B8\u03B1"
}, {
  find: "the",
  replace: "\u03B8\u03B5"
}, {
  find: "thi",
  replace: "\u03B8\u03B9"
}, {
  find: "thh",
  replace: "\u03B8\u03B7"
}, {
  find: "tho",
  replace: "\u03B8\u03BF"
}, {
  find: "(thy|thu)",
  replace: "\u03B8\u03C5"
}, {
  find: "(thw|thv)",
  replace: "\u03B8\u03C9"
}, {
  find: "tH",
  replace: "\u03C4\u0397"
}, {
  find: "TH",
  replace: "\u03A4\u0397"
}, {
  find: "Th",
  replace: "\u03A4\u03B7"
}, {
  find: "th",
  replace: "\u03C4\u03B7"
}, {
  find: "(cH|ch)",
  replace: "\u03C7"
}, {
  find: "(CH|Ch)",
  replace: "\u03A7"
}, {
  find: "(PS|Ps)",
  replace: "\u03A8"
}, {
  find: "(ps|pS)",
  replace: "\u03C8"
}, {
  find: "(Ks|KS)",
  replace: "\u039E"
}, {
  find: "(ks|kS)",
  replace: "\u03BE"
}, {
  find: "(VR)",
  replace: "\u0392\u03A1"
}, {
  find: "(vr|vR)",
  replace: "\u03B2\u03C1"
}, {
  find: "(Vr)",
  replace: "\u0392\u03C1"
}, {
  find: "8a",
  replace: "\u03B8\u03B1"
}, {
  find: "8e",
  replace: "\u03B8\u03B5"
}, {
  find: "8h",
  replace: "\u03B8\u03B7"
}, {
  find: "8i",
  replace: "\u03B8\u03B9"
}, {
  find: "8o",
  replace: "\u03B8\u03BF"
}, {
  find: "8y",
  replace: "\u03B8\u03C5"
}, {
  find: "8u",
  replace: "\u03B8\u03C5"
}, {
  find: "(8v|8w)",
  replace: "\u03B8\u03C9"
}, {
  find: "8A",
  replace: "\u0398\u0391"
}, {
  find: "8E",
  replace: "\u0398\u0395"
}, {
  find: "8H",
  replace: "\u0398\u0397"
}, {
  find: "8I",
  replace: "\u0398\u0399"
}, {
  find: "8O",
  replace: "\u0398\u039F"
}, {
  find: "(8Y|8U)",
  replace: "\u0398\u03A5"
}, {
  find: "8V",
  replace: "\u0398\u03A9"
}, {
  find: "8W",
  replace: "\u0398\u03A9"
}, {
  find: "9a",
  replace: "\u03B8\u03B1"
}, {
  find: "9e",
  replace: "\u03B8\u03B5"
}, {
  find: "9h",
  replace: "\u03B8\u03B7"
}, {
  find: "9i",
  replace: "\u03B8\u03B9"
}, {
  find: "9o",
  replace: "\u03B8\u03BF"
}, {
  find: "9y",
  replace: "\u03B8\u03C5"
}, {
  find: "9u",
  replace: "\u03B8\u03C5"
}, {
  find: "(9v|9w)",
  replace: "\u03B8\u03C9"
}, {
  find: "9A",
  replace: "\u0398\u0391"
}, {
  find: "9E",
  replace: "\u0398\u0395"
}, {
  find: "9H",
  replace: "\u0398\u0397"
}, {
  find: "9I",
  replace: "\u0398\u0399"
}, {
  find: "9O",
  replace: "\u0398\u039F"
}, {
  find: "(9Y|9U)",
  replace: "\u0398\u03A5"
}, {
  find: "9V",
  replace: "\u0398\u03A9"
}, {
  find: "9W",
  replace: "\u0398\u03A9"
}, {
  find: "s[\\n]",
  replace: "\u03C2\n"
}, {
  find: "s[\\!]",
  replace: "\u03C2!"
}, {
  find: "s[\\.]",
  replace: "\u03C2."
}, {
  find: "s[\\ ]",
  replace: "\u03C2 "
}, {
  find: "s[\\,]",
  replace: "\u03C2,"
}, {
  find: "s[\\+]",
  replace: "\u03C2+"
}, {
  find: "s[\\-]",
  replace: "\u03C2-"
}, {
  find: "s[\\(]",
  replace: "\u03C2("
}, {
  find: "s[\\)]",
  replace: "\u03C2)"
}, {
  find: "s[\\[]",
  replace: "\u03C2["
}, {
  find: "s[\\]]",
  replace: "\u03C2]"
}, {
  find: "s[\\{]",
  replace: "\u03C2{"
}, {
  find: "s[\\}]",
  replace: "\u03C2}"
}, {
  find: "s[\\<]",
  replace: "\u03C2<"
}, {
  find: "s[\\>]",
  replace: "\u03C2>"
}, {
  find: "s[\\?]",
  replace: "\u03C2;"
}, {
  find: "s[\\/]",
  replace: "\u03C2/"
}, {
  find: "s[\\:]",
  replace: "\u03C2:"
}, {
  find: "s[\\;]",
  replace: "\u03C2;"
}, {
  find: "s[\\\"]",
  replace: "\u03C2\""
}, {
  find: "s[\\']",
  replace: "\u03C2'"
}, {
  find: "s[\\f]",
  replace: "\u03C2\f"
}, {
  find: "s[\\r]",
  replace: "\u03C2\r"
}, {
  find: "s[\\t]",
  replace: "\u03C2\t"
}, {
  find: "s[\\v]",
  replace: "\u03C2\x0B"
}, {
  find: "rx",
  replace: "\u03C1\u03C7"
}, {
  find: "sx",
  replace: "\u03C3\u03C7"
}, {
  find: "Sx",
  replace: "\u03A3\u03C7"
}, {
  find: "SX",
  replace: "\u03A3\u03A7"
}, {
  find: "ux",
  replace: "\u03C5\u03C7"
}, {
  find: "Ux",
  replace: "\u03A5\u03C7"
}, {
  find: "UX",
  replace: "\u03A5\u03A7"
}, {
  find: "yx",
  replace: "\u03C5\u03C7"
}, {
  find: "Yx",
  replace: "\u03A5\u03C7"
}, {
  find: "YX",
  replace: "\u03A5\u03A7"
}, {
  find: "3a",
  replace: "\u03BE\u03B1"
}, {
  find: "3e",
  replace: "\u03BE\u03B5"
}, {
  find: "3h",
  replace: "\u03BE\u03B7"
}, {
  find: "3i",
  replace: "\u03BE\u03B9"
}, {
  find: "3\u03BF",
  replace: "\u03BE\u03BF"
}, {
  find: "3u",
  replace: "\u03BE\u03C5"
}, {
  find: "3y",
  replace: "\u03BE\u03C5"
}, {
  find: "3v",
  replace: "\u03BE\u03C9"
}, {
  find: "3w",
  replace: "\u03BE\u03C9"
}, {
  find: "a3",
  replace: "\u03B1\u03BE"
}, {
  find: "e3",
  replace: "\u03B5\u03BE"
}, {
  find: "h3",
  replace: "\u03B7\u03BE"
}, {
  find: "i3",
  replace: "\u03B9\u03BE"
}, {
  find: "\u03BF3",
  replace: "\u03BF\u03BE"
}, {
  find: "u3",
  replace: "\u03C5\u03BE"
}, {
  find: "y3",
  replace: "\u03C5\u03BE"
}, {
  find: "v3",
  replace: "\u03C9\u03BE"
}, {
  find: "w3",
  replace: "\u03C9\u03BE"
}, {
  find: "3A",
  replace: "\u03BE\u0391"
}, {
  find: "3E",
  replace: "\u03BE\u0395"
}, {
  find: "3H",
  replace: "\u03BE\u0397"
}, {
  find: "3I",
  replace: "\u03BE\u0399"
}, {
  find: "3O",
  replace: "\u03BE\u039F"
}, {
  find: "3U",
  replace: "\u03BE\u03A5"
}, {
  find: "3Y",
  replace: "\u03BE\u03A5"
}, {
  find: "3V",
  replace: "\u03BE\u03A9"
}, {
  find: "3W",
  replace: "\u03BE\u03A9"
}, {
  find: "A3",
  replace: "\u0391\u03BE"
}, {
  find: "E3",
  replace: "\u0395\u03BE"
}, {
  find: "H3",
  replace: "\u0397\u03BE"
}, {
  find: "I3",
  replace: "\u0399\u03BE"
}, {
  find: "O3",
  replace: "\u039F\u03BE"
}, {
  find: "U3",
  replace: "\u03A5\u03BE"
}, {
  find: "Y3",
  replace: "\u03A5\u03BE"
}, {
  find: "V3",
  replace: "\u03A9\u03BE"
}, {
  find: "W3",
  replace: "\u03A9\u03BE"
}, {
  find: "A",
  replace: "\u0391"
}, {
  find: "a",
  replace: "\u03B1"
}, {
  find: "B",
  replace: "\u0392"
}, {
  find: "b",
  replace: "\u03B2"
}, {
  find: "V",
  replace: "\u0392"
}, {
  find: "v",
  replace: "\u03B2"
}, {
  find: "c",
  replace: "\u03C8"
}, {
  find: "C",
  replace: "\u03A8"
}, {
  find: "G",
  replace: "\u0393"
}, {
  find: "g",
  replace: "\u03B3"
}, {
  find: "D",
  replace: "\u0394"
}, {
  find: "d",
  replace: "\u03B4"
}, {
  find: "E",
  replace: "\u0395"
}, {
  find: "e",
  replace: "\u03B5"
}, {
  find: "Z",
  replace: "\u0396"
}, {
  find: "z",
  replace: "\u03B6"
}, {
  find: "H",
  replace: "\u0397"
}, {
  find: "h",
  replace: "\u03B7"
}, {
  find: "U",
  replace: "\u0398"
}, {
  find: "u",
  replace: "\u03C5"
}, {
  find: "I",
  replace: "\u0399"
}, {
  find: "i",
  replace: "\u03B9"
}, {
  find: "j",
  replace: "\u03BE"
}, {
  find: "J",
  replace: "\u039E"
}, {
  find: "K",
  replace: "\u039A"
}, {
  find: "k",
  replace: "\u03BA"
}, {
  find: "L",
  replace: "\u039B"
}, {
  find: "l",
  replace: "\u03BB"
}, {
  find: "M",
  replace: "\u039C"
}, {
  find: "m",
  replace: "\u03BC"
}, {
  find: "N",
  replace: "\u039D"
}, {
  find: "n",
  replace: "\u03BD"
}, {
  find: "X",
  replace: "\u03A7"
}, {
  find: "x",
  replace: "\u03C7"
}, {
  find: "O",
  replace: "\u039F"
}, {
  find: "o",
  replace: "\u03BF"
}, {
  find: "P",
  replace: "\u03A0"
}, {
  find: "p",
  replace: "\u03C0"
}, {
  find: "R",
  replace: "\u03A1"
}, {
  find: "r",
  replace: "\u03C1"
}, {
  find: "S",
  replace: "\u03A3"
}, {
  find: "s",
  replace: "\u03C3"
}, {
  find: "T",
  replace: "\u03A4"
}, {
  find: "t",
  replace: "\u03C4"
}, {
  find: "Y",
  replace: "\u03A5"
}, {
  find: "y",
  replace: "\u03C5"
}, {
  find: "F",
  replace: "\u03A6"
}, {
  find: "f",
  replace: "\u03C6"
}, {
  find: "W",
  replace: "\u03A9"
}, {
  find: "w",
  replace: "\u03C9"
}, {
  find: "\\?",
  replace: ";"
}];
exports = greeklishToGreekMap;
export default exports;