var exports = {};
const greekToTransliteratedLatinMap = [{
  find: "\u03B1",
  replace: "a"
}, {
  find: "\u03B2",
  replace: "v"
}, {
  find: "\u03B3",
  replace: "g"
}, {
  find: "\u03B4",
  replace: "d"
}, {
  find: "\u03B5",
  replace: "e"
}, {
  find: "\u03B6",
  replace: "z"
}, {
  find: "\u03B7",
  replace: "\u0113"
}, {
  find: "\u03B8",
  replace: "th"
}, {
  find: "\u03B9",
  replace: "i"
}, {
  find: "\u03BA",
  replace: "k"
}, {
  find: "\u03BB",
  replace: "l"
}, {
  find: "\u03BC",
  replace: "m"
}, {
  find: "\u03BD",
  replace: "n"
}, {
  find: "\u03BE",
  replace: "x"
}, {
  find: "\u03BF",
  replace: "o"
}, {
  find: "\u03C0",
  replace: "p"
}, {
  find: "\u03C1",
  replace: "r"
}, {
  find: "\u03C3",
  replace: "s"
}, {
  find: "\u03C2",
  replace: "s"
}, {
  find: "\u03C4",
  replace: "t"
}, {
  find: "\u03C5",
  replace: "u"
}, {
  find: "\u03C6",
  replace: "ph"
}, {
  find: "\u03C7",
  replace: "kh"
}, {
  find: "\u03C8",
  replace: "ps"
}, {
  find: "\u03C9",
  replace: "\u014D"
}, {
  find: "\u03AC",
  replace: "\xE1"
}, {
  find: "\u03AD",
  replace: "\xE9"
}, {
  find: "\u03AF",
  replace: "\xED"
}, {
  find: "\u03CC",
  replace: "\xF3"
}, {
  find: "\u03CD",
  replace: "\xFA"
}, {
  find: "\u03AE",
  replace: "\u1E17"
}, {
  find: "\u03CE",
  replace: "\u1E53"
}, {
  find: "\u03CA",
  replace: "\xEF"
}, {
  find: "\u03CB",
  replace: "\xFC"
}, {
  find: "\u03B0",
  replace: "\u01D8"
}, {
  find: "\u0390",
  replace: "\u1E2F"
}, {
  find: "\u0391",
  replace: "A"
}, {
  find: "\u0392",
  replace: "V"
}, {
  find: "\u0393",
  replace: "G"
}, {
  find: "\u0394",
  replace: "D"
}, {
  find: "\u0395",
  replace: "E"
}, {
  find: "\u0396",
  replace: "Z"
}, {
  find: "\u0397",
  replace: "\u0112"
}, {
  find: "\u0398",
  replace: "Th"
}, {
  find: "\u0399",
  replace: "I"
}, {
  find: "\u039A",
  replace: "K"
}, {
  find: "\u039B",
  replace: "L"
}, {
  find: "\u039C",
  replace: "M"
}, {
  find: "\u039D",
  replace: "N"
}, {
  find: "\u039E",
  replace: "X"
}, {
  find: "\u039F",
  replace: "O"
}, {
  find: "\u03A0",
  replace: "P"
}, {
  find: "\u03A1",
  replace: "R"
}, {
  find: "\u03A3",
  replace: "S"
}, {
  find: "\u03A4",
  replace: "T"
}, {
  find: "\u03A5",
  replace: "U"
}, {
  find: "\u03A6",
  replace: "Ph"
}, {
  find: "\u03A7",
  replace: "Kh"
}, {
  find: "\u03A8",
  replace: "Ps"
}, {
  find: "\u03A9",
  replace: "\u014C"
}, {
  find: "\u0386",
  replace: "\xC1"
}, {
  find: "\u0388",
  replace: "\xC9"
}, {
  find: "\u038A",
  replace: "\xCD"
}, {
  find: "\u038C",
  replace: "\xD3"
}, {
  find: "\u038E",
  replace: "\xDA"
}, {
  find: "\u0389",
  replace: "\u1E16"
}, {
  find: "\u038F",
  replace: "\u1E52"
}, {
  find: "\u03AA",
  replace: "\xCF"
}, {
  find: "\u03AB",
  replace: "\xDC"
}, {
  find: ";",
  replace: "?"
}];
exports = greekToTransliteratedLatinMap;
export default exports;