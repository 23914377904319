var exports = {};
const greekToGreeklishMap = [{
  find: "\u0393\u03A7",
  replace: "GX"
}, {
  find: "\u03B3\u03C7",
  replace: "gx"
}, {
  find: "\u03A4\u0398",
  replace: "T8"
}, {
  find: "\u03C4\u03B8",
  replace: "t8"
}, {
  find: "(\u03B8\u03B7|\u0398\u03B7)",
  replace: "8h"
}, {
  find: "\u0398\u0397",
  replace: "8H"
}, {
  find: "\u03B1\u03C5",
  replace: "au"
}, {
  find: "\u0391\u03C5",
  replace: "Au"
}, {
  find: "\u0391\u03A5",
  replace: "AY"
}, {
  find: "\u03B5\u03C5",
  replace: "eu"
}, {
  find: "\u03B5\u03CD",
  replace: "eu"
}, {
  find: "\u03B5\u03CB",
  replace: "ey"
}, {
  find: "\u03B5\u03B0",
  replace: "ey"
}, {
  find: "\u0395\u03C5",
  replace: "Eu"
}, {
  find: "\u0395\u03CD",
  replace: "Eu"
}, {
  find: "\u0395\u03CB",
  replace: "Ey"
}, {
  find: "\u0395\u03B0",
  replace: "Ey"
}, {
  find: "\u0395\u03A5",
  replace: "EY"
}, {
  find: "\u03BF\u03C5",
  replace: "ou"
}, {
  find: "\u03BF\u03CD",
  replace: "ou"
}, {
  find: "\u03BF\u03CB",
  replace: "oy"
}, {
  find: "\u03BF\u03B0",
  replace: "oy"
}, {
  find: "\u039F\u03C5",
  replace: "Ou"
}, {
  find: "\u039F\u03CD",
  replace: "Ou"
}, {
  find: "\u039F\u03CB",
  replace: "Oy"
}, {
  find: "\u039F\u03B0",
  replace: "Oy"
}, {
  find: "\u039F\u03A5",
  replace: "OY"
}, {
  find: "\u0391",
  replace: "A"
}, {
  find: "\u03B1",
  replace: "a"
}, {
  find: "\u03AC",
  replace: "a"
}, {
  find: "\u0386",
  replace: "A"
}, {
  find: "\u0392",
  replace: "B"
}, {
  find: "\u03B2",
  replace: "b"
}, {
  find: "\u0393",
  replace: "G"
}, {
  find: "\u03B3",
  replace: "g"
}, {
  find: "\u0394",
  replace: "D"
}, {
  find: "\u03B4",
  replace: "d"
}, {
  find: "\u0395",
  replace: "E"
}, {
  find: "\u03B5",
  replace: "e"
}, {
  find: "\u03AD",
  replace: "e"
}, {
  find: "\u0388",
  replace: "E"
}, {
  find: "\u0396",
  replace: "Z"
}, {
  find: "\u03B6",
  replace: "z"
}, {
  find: "\u0397",
  replace: "H"
}, {
  find: "\u03B7",
  replace: "h"
}, {
  find: "\u03AE",
  replace: "h"
}, {
  find: "\u0389",
  replace: "H"
}, {
  find: "\u0398",
  replace: "TH"
}, {
  find: "\u03B8",
  replace: "th"
}, {
  find: "\u0399",
  replace: "I"
}, {
  find: "\u03AA",
  replace: "I"
}, {
  find: "\u03B9",
  replace: "i"
}, {
  find: "\u03AF",
  replace: "i"
}, {
  find: "\u0390",
  replace: "i"
}, {
  find: "\u03CA",
  replace: "i"
}, {
  find: "\u038A",
  replace: "I"
}, {
  find: "\u039A",
  replace: "K"
}, {
  find: "\u03BA",
  replace: "k"
}, {
  find: "\u039B",
  replace: "L"
}, {
  find: "\u03BB",
  replace: "l"
}, {
  find: "\u039C",
  replace: "M"
}, {
  find: "\u03BC",
  replace: "m"
}, {
  find: "\u039D",
  replace: "N"
}, {
  find: "\u03BD",
  replace: "n"
}, {
  find: "\u039E",
  replace: "KS"
}, {
  find: "\u03BE",
  replace: "ks"
}, {
  find: "\u039F",
  replace: "O"
}, {
  find: "\u03BF",
  replace: "o"
}, {
  find: "\u038C",
  replace: "O"
}, {
  find: "\u03CC",
  replace: "o"
}, {
  find: "\u03A0",
  replace: "P"
}, {
  find: "\u03C0",
  replace: "p"
}, {
  find: "\u03A1",
  replace: "R"
}, {
  find: "\u03C1",
  replace: "r"
}, {
  find: "\u03A3",
  replace: "S"
}, {
  find: "\u03C3",
  replace: "s"
}, {
  find: "\u03A4",
  replace: "T"
}, {
  find: "\u03C4",
  replace: "t"
}, {
  find: "\u03A5",
  replace: "Y"
}, {
  find: "\u038E",
  replace: "Y"
}, {
  find: "\u03AB",
  replace: "Y"
}, {
  find: "\u03B0",
  replace: "y"
}, {
  find: "\u03CD",
  replace: "y"
}, {
  find: "\u03CB",
  replace: "y"
}, {
  find: "\u03C5",
  replace: "y"
}, {
  find: "\u03A6",
  replace: "F"
}, {
  find: "\u03C6",
  replace: "f"
}, {
  find: "\u03A7",
  replace: "X"
}, {
  find: "\u03C7",
  replace: "x"
}, {
  find: "\u03A8",
  replace: "Ps"
}, {
  find: "\u03C8",
  replace: "ps"
}, {
  find: "\u03A9",
  replace: "w"
}, {
  find: "\u03C9",
  replace: "w"
}, {
  find: "\u038F",
  replace: "w"
}, {
  find: "\u03CE",
  replace: "w"
}, {
  find: "\u03C2",
  replace: "s"
}, {
  find: ";",
  replace: "?"
}];
exports = greekToGreeklishMap;
export default exports;